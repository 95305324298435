.event-box {
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 498px;
    position: relative;
}

.event {
    display: flex;
    align-items: center; /* Center elements vertically */
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Add a subtle border between items */
}

.event:last-child {
    border-bottom: none; /* Remove the border on the last item */
}

.event:hover {
    cursor: pointer;
    background-color: #f1f1f1; /* Highlight on hover */
}

.category {
    flex: 1;
    display: flex;
    flex-direction: column; /* Stack category elements vertically */
    justify-content: center; /* Center elements horizontally */
    align-items: flex-start; /* Align elements to the left side */
    padding: 8px;
}

.category-title-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    padding: 10px;
    background-color: #f5f5f5; /* Gray background for category titles */
}

.category-element {
    margin-top: 5px;
}

.create-event-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.line {
    display: block;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    background-color: #d8868d; /* Blue background for the title */
    color: #000000; /* White text for the title */
    border-top-left-radius: 50%; /* Rounded corners only for the top-left and top-right corners */
    border-top-right-radius: 50%;
}

/* Additional styles for the "No events created yet" section */
.no-events {
    font-weight: 600;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: -20px;
}

.no-event-box {
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-size: 26px;
    padding: 10px;
    background-color: #f5f5f5; /* Gray background for the "No events created yet" section */
    border: 1px solid #ccc; /* Add a border around the section */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}
