body {
  background-color: #ffffff; /* Dark background color */
  color: #000000; /* Light text color */
}

.category-title-box {
  /* color: rgb(255, 255, 255) */
}

.profile-dropdown {
  position: absolute;
  z-index: 1000;
  /* border: 1px solid grey; */
  border-radius: 25%;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
  margin-left: -100px;
  background-color: rgb(255, 255, 255);
}

.profile-dropdown li {
  margin: 5px;
}

.profile-dropdown button {
  margin-right: 15px;
  margin: 5px;
}

.hidden {
  display: none;
}

.profile-button {
  justify-items: flex-end;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  margin-left: 85%;
}

@media (max-width: 1500px) {
  .profile-button {
    margin-left: 80%;
  }
}

@media (max-width: 1000px) {
  .profile-button {
    margin-left: 0%;
  }
}

.nav-bar {
  display: flex;
  flex-direction: row;
}

li{
  list-style-type: none;
}

.white-space:not(button){
  color: white;
}