.event-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-details {
    margin-bottom: 20px;
    width: 80%;
    border: 1px solid rgb(141, 140, 140);
    border-radius: 10%;
    box-shadow: inset;
    padding: 10px;
}

.event-title {
    padding: 10px;
    font-size: 24px;
    font-weight: bold;
}

.upper-right {
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: flex-end;
}

.event-info {
    display: flex;
    align-items: center;
}

.long-members {
    flex-direction: row;
    width: 250px;
}

.event-date,
.event-time {
    margin-right: 10px;
}

.member-count {
    margin-right: 10px;
    font-size: 14px;
}

.event-description {
    margin-bottom: 20px;
    overflow-wrap: break-word;
    overflow-y: auto;
    max-width: 500px;

    max-height: 200px;

    padding: 10px;
    border-radius: 10px;
}

.task-section {
    margin-top: 20px;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
}


.task-row {
    margin-bottom: 10px;
}

.task-details {
    display: flex;
    align-items: center;
    gap: 80px;
}

.task-el {
    margin-right: 10px;
    margin-left: 10px;
}

.task-el-title {
    margin-right: 10px;
    ;
}


.task-el-details {
    max-width: 250px;
    min-width: 25px;
    overflow-y: auto;
    width: 250px;

    max-height: 250px;
    padding: 5px;

    margin-right: 10px;

}

.edit-button,
.add-members-button,
.add-task-button,
.guest-count {
    margin-right: 10px;
    /* Add spacing between buttons */
    margin-bottom: 10px;
    margin-top: 10px;
}



.add-task-button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

@media screen and (max-width: 1000px) {
    .event-details {
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 800px;
        /* Add this line */
        margin: 0 auto;
        /* Add this line to center the container */
    }

    .event-info {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .event-date,
    .event-time,
    .member-count {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .event-description {
        max-width: 100%;
        max-height: none;
    }

    .buttons {
        flex-direction: column;
        align-items: center;
    }

    .buttons>* {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.buttons>* {
    margin-right: 10px;
}