.confirmation-container {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.confirmation-text {
    text-align: center;
    margin-bottom: 20px;
}

.confirmation-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm-button,
.cancel-button {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #f44336;
}