.signup-back {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f2f2f2;
}

.signup-back h1 {
    text-align: center;
    margin-bottom: 20px;
}

.signup-back form {
    display: flex;
    flex-direction: column;
}

.signup-back ul {
    list-style: none;
    padding: 0;
    margin-bottom: 10px;
}

.signup-back label {
    display: block;
    margin-bottom: 10px;
}

.signup-back input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.signup-back button[type="submit"] {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: #0066cc;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.signup-back button[type="submit"]:hover {
    background-color: #0052a3;
}

.error {
    color: red
}

@media screen and (max-width: 600px) {
    .signup-back {
        max-width: 100%;
    }

    .signup-back input {
        width: 100%;
    }
}