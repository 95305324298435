.home-container {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

@media (max-width: 1000px) {
    .home-container {
        flex-direction: column;
        margin-top: 1rem;
    }
    .finance-container {
        width: 100%
    }
    .event-container {
        width: 100%;
    }
}

.event-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid #d8868d;
    border-radius: 10%;
    width: 48%;
    height: 600px;
    position: relative;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    box-sizing: border-box; /* Include the border in the element's total width */
}

.finance-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid #7cbd7f;
    border-radius: 10%;
    width: 48%;
    height: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    box-sizing: border-box; /* Include the border in the element's total width */
}

.home-element {
    justify-content: center;
    align-content: center;
    margin-left: 28%;
    font-weight: 600;
    font-size: 24px;
}

.title-finance {
    margin-bottom: 10%;
    padding-bottom: 10%;
    align-self: center;
}



.task-box {
    border: 1px solid black;
    background-color: rgb(216, 216, 216);
    border-radius: 13%;
    margin-top: 4%;
    width: 95%;
    height: 250px;
    margin-bottom: 2rem;
    padding-left: 2rem;
    position: relative;
    overflow-y: auto;
    margin: 0 auto;
    /* Add this line */
}

.background {
    background-color: rgb(209, 179, 195);
}

.welcome-box {
    max-width: 800px;

    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.welcome-h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

.welcome-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.welcome-p {
    margin-bottom: 10px;
}

.h2-welcome {
    font-size: 24px;
    margin-top: 30px;
}

.div-welcome {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 30px;
}

h3 {
    margin-top: 40px;
    font-style: italic;
}

@media (max-width: 1000px) {
    .event-contain{
        width: 100%
    }
    .event-container{
        width: 100%;
        margin: 0 auto
    }
    .finance-container{
        width: 100%;
        margin: 0 auto
    }
}

.footer.signedin {
    position: absolute;
    bottom: -275;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: -250px;
    background-color: #f5f5f5;
    border-top: 1px solid #ccc;
    margin: 25px;
}

a {
    color: blue
}