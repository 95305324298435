.task-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2%;
    font-weight: 600;
    flex-wrap: wrap;
}

.task-el {
    padding: 1px;
}

.task-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 95%;
}

.task-details {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15%;
    margin-top: 1%;
    overflow-x: auto;
    /* Add overflow-x property for side-to-side scroll */
    white-space: nowrap;
    /* Prevent line breaks */
}

.edit-button {
    cursor: pointer;
}

.task-el-detail {
    overflow-y: auto;
    max-width: 150px;
}

.task-el {
    cursor: default;
}

.task-title {
    position: absolute;
    top: -7.5%;
    left: 50%;
    right: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    z-index: 1;
}

.task-delete {
    color: red;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 1%;
}

.no-task {
    margin-top: 8%;
    margin-left: 35%;
    align-self: center;
    justify-content: space-around;
    color: red;
}

@media (max-width: 1000px) {
    .task-details {
        flex-wrap: nowrap;
        margin-left: 0px;
        /* Revert flex-wrap to nowrap for side-to-side scroll */
    }

    .task-details .task-el {
        margin-right: 20px;
        margin-left: 2px;
        /* Increase the minimum space between elements */
    }

    .task-title {
        position: static;
        margin-bottom: 10px;
        transform: translateX(-50%);
        width: max-content;
    }
}