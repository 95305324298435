.form-container {
    width: 750px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
}



.form-group {
    margin-bottom: 20px;
    margin-right: 10px;
}

input,
textarea,
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    height: 100px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.error-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    color: red;
}

.error-list li {
    margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
    .form-container {
        width: 100%;
        padding: 10px;
    }

    .form-group {
        margin-bottom: 15px;
        margin-right: 50px;
    }

    input,
    textarea,
    select {
        padding: 8px;
        font-size: 14px;
    }

    .submit-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}