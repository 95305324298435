/* .finance-contain {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(255, 255, 255);
    border-radius: 10%;
    width: 100%;
    height: 600px;
    margin: 0 auto;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 20px;
} */

.title.finance {
    background-color: #7cbd7f; 
    border: 0px
}

.finance-box {
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 498px;
    position: relative;
}

.finance-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #000000;
}

.finance-item:last-child {
    border-bottom: none;
}

.finance-item:hover {
    background-color: #f1f1f1;
}

.category-title-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    padding: 10px;
    background-color: #f5f5f5;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
}

.category {
    margin-top: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
}

.create-finance-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.context-menu {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #000000;
    padding: 5px;
    z-index: 9999;
}

.context-menu-item {
    cursor: pointer;
    padding: 5px;
}

.context-menu-item.hovered {
    background-color: #dfdfdf;
}