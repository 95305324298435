.members-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.members-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.element {
    flex: 1;
    padding: 5px;
}

select {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
}

select:focus {
    outline: none;
}

.non-member {
    padding: 10px;
    color: red;
}

.member-button {
    font-weight: 600;
}