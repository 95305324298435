.user-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.users-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    cursor: pointer;
}

.element {
    flex: 1;
    padding: 5px;
}

.add-member-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-member-form input[type="text"] {
    width: 300px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.add-member-form button {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
}

.add-member-form button:hover {
    background-color: #e0e0e0;
}

.add-member-form .error-message {
    color: red;
    margin-top: 10px;
}