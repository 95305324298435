.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

h1 {
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
}

input,
textarea,
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    height: 100px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.error-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    color: red;
}

.error-list li {
    margin-bottom: 5px;
}

.highlight {
    border: 5px solid red;
}